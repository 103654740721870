<template>
  <div class="order-brief">
    <feature-list :cart="order.cart" class="mb-4" />
    <addons :addons="addons" class="mb-4" />
    <requirements  :requirements="requirements" />
  </div>
</template>

<script>

import FeatureList from "./FeatureList.vue"
import Requirements from "./Requirements.vue"
import Addons from "./Addons.vue"

export default {
  components:{
    Addons,
    Requirements,
    FeatureList
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    addons(){
      return this.order.cart.addons
    },
    requirements(){
      return this.order.cart.requirements
    },
  }
}

</script>

