<template>
  <div class="order-overview">
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <button @click="$router.go(-1)" type="button" class="btn btn-dark"> Back</button> 
        </div>
      </div>
    </div>  
    <div v-if="isLoading" class="row">
      <div class="col-12">
        <is-loading />
      </div>
    </div>
    <div v-if="!isLoading" class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div v-if="order.status == 'draft'" >
              <b-alert :show="true"  variant="warning">
                <h5 class="alert-heading">Saved as draft!</h5>
                <p class="mb-0">
                  <span class="me-2">This order was saved as draft. Proceed to make payment so that this order can be processed.</span>
                </p>
                <hr class="my-2">
                <div class="d-flex justify-content-end">
                  <a class="btn btn-outline-success me-2" :href="absoluteUrl(`/projects/${order.id}/payment`)">Pay Now!</a>
                  <a class="btn btn-outline-danger" @click.prevent="deleteDraft()" href="#">Delete Draft!</a>
                </div>
              </b-alert>
            </div>
            <div v-else>
              <div class="row mb-4">
                <div class="col-md-6 col-lg-4">
                  <div class="mb-2"> <span class="me-2">Order No: <strong>#{{order.number}}</strong></span> </div>
                  <div class="mb-2">Status: <span class="me-2" v-html="statusBadge(order.status)"></span>  </div>
                  <div class="mb-2">Total Price: <strong class="text-uppercase">${{$filters.money_format(order.amount)}}</strong> </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="mb-2">Product: <strong class="text-uppercase me-2">{{product.name}}</strong> </div>
                  <div class="mb-2">Date: <strong class="text-uppercase me-2">{{ $filters.date_time(order.created_at,1) }}</strong> </div>
                  <div class="mb-2">Delivery date: <strong class="text-uppercase me-2">{{ $filters.deliveryDate(order.created_at, order.delivery_time)}}</strong> </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div v-if="order.cart.coupon" class="mb-2">Discount ({{order.cart.coupon.percent}}%): <strong class="text-uppercase">${{$filters.money_format(order.cart.coupon.discount)}}</strong> </div>
                  <div ><a class="text-success" :href="absoluteUrl(`/${product.slug}`)">Place another order for this product</a> </div>
                </div>
              </div>
              <b-alert v-if="order.status == 'delivered'" :show="true"  variant="success">
                <h5 class="alert-heading">Your delivery is here!</h5>
                <p class="mb-2">
                  <span class="me-2">View the delivery to make sure you have exactly what you need. Let us know your thoughts in the conversation below! </span>
                </p>
                <div class="">
                  <button type="button" class="btn btn-outline-success" @click.prevent="markCompleted()" >Yes, I approve delivery</button>
                </div>
              </b-alert>
              <b-alert v-if="canMakeReview" :show="true"  variant="success">
                <h5 class="alert-heading">Rate our services</h5>
                <p class="mb-2">
                  <span class="me-2">Write a brief review and rate this order. Your feedback is essential to our success, and we genuinely value your input. Thank you for helping us serve you better! </span>
                </p>
                <div class="">
                  <button type="button" class="btn btn-outline-success" @click.prevent="popupAddReview = true"> Rate now</button>
                </div>
              </b-alert>
            </div>
          </div>
          <div class="col-12">
            <b-tabs active-tab-class="active" 
              nav-class="bg-white shadow nav-tabs-custom nav-dark mb-3">
              <b-tab :active="activeTab === 2">
                <template v-slot:title>
                  <i class="mdi mdi-file-image me-1"></i>
                  <span class="nav-label">Conversations</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <order-messages />
                  </div>
                </div>
              </b-tab>
              <b-tab :active="activeTab === 1">
                <template v-slot:title>
                  <i class="mdi mdi-book-outline me-1"></i>
                  <span class="nav-label">Brief</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <order-brief />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <b-modal v-model="popupAddReview" hide-footer centered
          title="Submit Review" body-class="p-3" title-class="small" @hide="initializeAll()">
          <div>
            <order-review-form v-if="popupAddReview"  @closeMe="initializeAll()"/>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import OrderBrief from "@/components/order/OrderBrief.vue"
import OrderMessages from '@/components/order/OrderMessages.vue'
import IsLoading from '@/components/IsLoading.vue'
import OrderReviewForm from '@/components/forms/OrderReviewForm.vue'

export default {
  name: "order-overview",
  components: {
    IsLoading,
    OrderBrief,
    OrderMessages,
    OrderReviewForm
  },
  data() {
    return {
      isLoading: true,
      popupAddReview: false
    }
  },
  watch: {
    '$route.params.orderId': function (id){
      if(id) this.fetchOrder()
    },
  },
  computed: {
    canMakeReview(){
      return this.order.status == 'completed' && !this.order.is_reviewed
    },
    order(){
      return this.$store.state.orderList.order
    },
    activeUser(){
      return this.$store.state.activeUser
    },
    product(){
      return this.order.cart.product
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 2;
    },
  },
  methods:{
    deleteDraft() {
      this.confirmDelete({
        text: "You are about to delete this order",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/orders/${this.order.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path:'/orders'})
            }
          })
        }
      });
    },
    markCompleted() {
       Swal.fire({
        title: "Are you sure?",
        text: "You are about to mark this order as completed",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post(`/orders/${this.order.id}/mark-completed`, {})
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.popupAddReview = true
              this.fetchOrder()
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupAddReview = false
    },
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder", this.$route.params.orderId)
      .then((response) => {
        if(response.data.success){
          this.isLoading = false
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
  },
  created(){
    this.fetchOrder()
  },

}
</script>

<style lang="scss">
</style>
