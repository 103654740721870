<template>
  <div class="order-message row">
    <div class="col-12">
      <SimpleBar style="max-height: 450px;"
        @created="instance => { simplebarInstance = instance;}">
        <ul class="conversation-list mb-0 p-3 list-unstyled">
          <li class="clearfix mb-3" v-for="(message, mIndex) in messages" :key="mIndex">
            <div class="odd" v-if="message.sender_id == activeUser.id">
              <message-content
                :buyer="{}"
                :message="message"
                :index="mIndex"
                @showImg="showImg($event)"
              >
                <template v-slot:actions>
                    <a class="me-2 text-success" href="javascript:void(0)" @click.prevent="initEditMessage(message)">Edit</a>
                    <a class="text-danger" href="javascript:void(0)" @click.prevent="deleteMessage(message.id)">Delete</a>
                </template>
              </message-content>
            </div>
            <div v-else >
              <message-content
                :buyer="{}"
                :message="message"
                :index="mIndex"
                @showImg="showImg($event)"
                >
                <template v-if="!message.replyer_id" v-slot:actions>
                    <a class="text-success" href="javascript:void(0)" @click.prevent="initReply(message.id)">Reply</a>
                </template>
              </message-content>
            </div>
          </li>
        </ul>
      </SimpleBar>
      <div class="pt-3">
        <form>
          <textarea  v-model="newMessage" class="form-control mb-0"
            rows="4" placeholder="Send message" name="content"></textarea>
          <div class="input-group pt-3">
            <button @click="modalSendVoice = !modalSendVoice" type="button"
              class="btn btn-light"> <i class="bx bxs-microphone"></i> </button>
            <button  @click.prevent="modalUploadFiles = true"  type="button"
              class="btn btn-dark"><i class="ri-attachment-2"></i></button>
            <button @click.prevent="createMessage()" type="button" class="btn btn-primary">
              Send message
            </button>
          </div>
        </form>
      </div>
      <b-modal v-model="modalEditReplyMessage" body-class="p-3" hide-footer centered
      :title="onEditMode?'Edit Message':'Reply Message'" title-class="small" @hide="initializeAll()">
        <form>
          <div class="mb-3">
            <textarea  v-model="onEdit.content" class="form-control mb-0"
              rows="4" placeholder="Write something" name="message"></textarea>
          </div>
          <div class="">
            <button @click.prevent="onEditMode ? updateMessage() : sendReply()"
              type="button" class="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </b-modal>
      <b-modal v-model="modalUploadFiles" hide-footer centered
        title="Upload Files" body-class="p-3" title-class="small" @hide="initializeAll()">
        <div>
          <upload-files v-if="modalUploadFiles" @uploaded="afterFileUpload()" />
        </div>
      </b-modal>
       <b-modal v-model="modalSendVoice" hide-footer centered
        title="Record voice" body-class="p-3" title-class="small" @hide="initializeAll()">
        <div>
          <audio-recorder v-if="modalSendVoice" @export-file="sendVoice($event)" />
        </div>
      </b-modal>
        <vue-easy-lightbox :visible="lightbox.visible" :imgs="lightboxFiles"
        :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>
    </div>
  </div>
</template>

<script>
import { SimpleBar } from "simplebar-vue3";
import MessageContent from "./MessageContent.vue";
import UploadFiles from "./UploadFiles.vue";
import VueEasyLightbox from "vue-easy-lightbox";
import AudioRecorder from '@/components/AudioRecorder.vue'

export default {
  components: {
    SimpleBar,
    MessageContent,
    UploadFiles,
    AudioRecorder,
    VueEasyLightbox
  },
  data() {
    return {
      newMessage: "",
      modalEditReplyMessage:false,
      onEdit: {},
      onEditMode: false,
      modalUploadFiles: false,
      modalSendVoice: false,
      lightbox:{
          index: null,
          visible:false,
          files:[]
      },
      simplebarInstance: null
    }
  },
  computed: {
    activeUser() {
      return this.$store.state.activeUser
    },
    order(){
      return this.$store.state.orderList.order
    },
    messages(){
      return this.order.messages
    },
    lightboxFiles(){
      return this.lightbox.files.map(file => {
        if(file.is_video){
          return {
            title: file.name,
            thumb: this.absoluteUrl(file.path),
            mediaType: 'video',
            src: this.absoluteUrl(file.video_path) }
        }else{
          return {
            title: file.name,
            mediaType: 'image',
            src: this.absoluteUrl(file.path) }
        }
      })
    },
  },
  methods: {
    initEditMessage(message){
      this.onEditMode = true
      this.onEdit = {
        id: message.id,
        content: message.content,
      }
      this.modalEditReplyMessage = true
    },
    updateMessage(){
      if(!this.onEdit.content){this.alertError("Please type Something"); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({content: this.onEdit.content}))
      this.resquestProcessor(formData, `${this.onEdit.id}/update`,'UPDATE')
    },
    createMessage(){
      if (!this.newMessage) { this.alertError("Message is required");return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({content: this.newMessage }))
      this.resquestProcessor(formData, `${this.order.id}/create`)
    },
    sendVoice(file){
      if (!file) { this.alertError("Audio file is required");return}
      let formData = new FormData()
      formData.append('file_0', file.blob, 'voice-message.mp3')
      formData.append('content', "Voice message")
      this.resquestProcessor(formData, `${this.order.id}/attachments`)
    },
    initReply(itemId){
      this.onEdit.id = itemId
      this.modalEditReplyMessage = true
      this.onEditMode = false
    },
    sendReply(){
      if(!this.onEdit.content){this.alertError("Please type Something"); return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({reply: this.onEdit.content}))
      this.resquestProcessor(formData, `${this.onEdit.id}/reply`, 'UPDATE')
    },
    resquestProcessor(formData, action, ftn='ADD'){
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/order-messages/${action}`,formData)
      .then(response => {
        this.$store.dispatch("changeLoaderValue", false)
        if(response.data.success){
          this.initializeAll()
          this.$store.commit(`orderList/${ftn}_MESSAGE`, response.data.data)
          if(ftn == 'ADD'){this.scrollMessageAreaUp();}
        }
     })
    },
    afterFileUpload(){
      this.initializeAll()
      this.scrollMessageAreaUp()
    },
    scrollMessageAreaUp(){
      setTimeout(() =>{
        if(!this.simplebarInstance) return
        this.simplebarInstance.getScrollElement().scrollTop = 
        this.simplebarInstance.getScrollElement().scrollHeight
      },500)
    },
    deleteMessage(itemId){
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete a message!",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#323a46",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/order-messages/${itemId}/delete`)
            .then((response) =>{
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit("orderList/DELETE_MESSAGE", itemId)
            }
          })
        }
      });
    },
    showImg(payload) {
      this.lightbox = {index: payload.index,visible: true,files: [...payload.files]};
    },
    initializeAll(){
      this.modalEditReplyMessage = false
      this.modalUploadFiles = false
      this.modalSendVoice = false
      this.onEditMode = false
      this.onEdit = {}
      this.newMessage = ""
      this.lightbox = { index: null,visible:false,files:[]};
    },
  },
  mounted(){
    this.scrollMessageAreaUp();
  },

}
</script>

<style lang="scss">
.conversation-list {
  min-height: 450px !important;
}

.conversation-list {
  .conversation-text {
    font-size: 12px;
    .ctext-wrap {
      padding:0.4rem;
      i {
        font-size: 12px;
      }
    }
  }
}

.conversation-list {
  .conversation-text {
    width: auto !important;
    max-width: 90% !important;
    border-radius:6px;
    background:#f7f8f9;
    color:#111b21;
  }
  .odd{
    .conversation-text{
        margin-left:auto;
        background:#d9fdd3;
    }
  }

}
</style>
