<template>
<div class="row g-3 order-attachments">
    <div v-for="(file, fIndex) in files" :key=" fIndex" 
         :class="`col-12 ${columnClass}`">
        <div v-if="isImageFile(file.extension)" class="border">
            <div @click="() => showImg(fIndex)"  class="attached-image-file"
                :style="`background-image: url(${absoluteUrl(file.path)})`" >
            </div>
            <div class="d-flex p-1 justify-content-between align-items-center">
                <span class="text-muted fs-12"> ({{file.size}}) - ({{file.extension}})</span>
                <a :href="absoluteUrl(`/download?file_path=${file.path}`)"
                                :download="file.name"><span class="bx bx-download"></span></a>
            </div>
        </div>
        <div v-else class="border">
            <div  class="attached-image-file d-flex align-items-center justify-content-center" >
                <h4 class="mb-0">{{file.size}} - {{file.extension}}</h4>
            </div>
            <div class="d-flex p-1 justify-content-between align-items-center">
                <span class="text-muted fs-12"> ({{file.size}}) - ({{file.extension}})</span>
                <a :href="absoluteUrl(`/download?file_path=${file.path}`)"
                                :download="file.name"><span class="bx bx-download"></span></a>
            </div>
        </div>
    </div>
    <vue-easy-lightbox :visible="visible" :imgs="lightboxFiles" :index="lightboxIndex" @hide="handleHide"></vue-easy-lightbox>
</div>
</template>

<script>

import VueEasyLightbox from "vue-easy-lightbox";

export default {
    name: "order-attachments",
    components:{
        VueEasyLightbox
    },
    props: {
        files: {
            type: Array,
            required: true,
        },
        columnClass:{
            type: String,
            default: "col-sm-6 mb-3",
        }
    },
    data() {
        return {
            visible: false,
            lightboxIndex: null,
        }
      },
    computed: {
        imageVideoFiles(){
            return this.files.filter((file) => this.isImageFile(file.extension) || file.is_video)
        },
        lightboxFiles(){
            return this.files.map(file => {
                if(file.is_video){
                  return {
                      title: file.name,
                      thumb: this.absoluteUrl(file.path),
                      mediaType: 'video',
                      src: this.absoluteUrl(file.video_path)
                    }
                }else{
                  return {
                      title: file.name,
                      mediaType: 'image',
                      src: this.absoluteUrl(file.path)
                    }
                }
            })
        },
    },
    methods: {
        showImg(index) {
            this.lightboxIndex = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
    }
}
</script>

<style scoped>

.order-attachments .attached-image-file {
    height:150px;
    background-position: center center;
    background-size: cover;
}

@media(max-width:568px){
    .order-attachments .attached-image-file {
        height:100px;
    }
}

</style>
