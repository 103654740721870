<template>
    <form>
        <div class="row gy-2">
            <div class="col-12">
                <div>
                    <star-rating 
                        v-model:rating="data.rating"
                        :star-size="30"></star-rating>
                </div>
                <div class="mt-3">
                    <div class="w-100">
                        <textarea  v-model="data.content" class="form-control mb-0"
                        :class="{ 'is-invalid': v$.data.content.$error }"
                        rows="5" placeholder="Share your experience" name="review"></textarea>
                        <div v-for="(item, index) in v$.data.content.$errors" :key="index"
                            class="invalid-feedback">
                            <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex align-items-end">
                <div class="text-center">
                    <button type="button" @click.prevent="submit()" class="btn btn-primary mt-2">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import StarRating from 'vue-star-rating'

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        StarRating
    },
    data() {
        return {
            data:{
                rating: null,
                order_id: this.$route.params.orderId,
                content: ""
            },
        }
    },
    computed:{
        order(){
            return this.$store.state.orderList.order
        },
    },
    validations() {
        return {
            data:{
                rating:{ required: required},
                content:{ required: required}
            }
        }
    },
    methods:{
        submit(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError("Form Not Valid"); return;}
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/reviews/create',formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.resetForm();
                        this.$store.dispatch("orderList/fetchOrder", this.$route.params.orderId)
                        this.$emit("closeMe")
                    }
                })
            });
        },
        resetForm() {
            this.data = {
                rating: null,
                order_id: this.$route.params.orderId,
                content: ""
            },
            this.v$.$reset()
        },
    },
}
</script>
